import { motion, useAnimation, useInView } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { Header30, Header48 } from "../_foundation/styleGuide/typography";
import BlackButton from "./Button/BlackButton";
import { redirectToAppStore } from "../function/redirectToAppStore";
import {
  BgGray050,
  BgGray200,
  BgGray700,
  BgMonoWhite,
} from "../_foundation/styleGuide/bgColors";

const ScreenShotPage = () => {
  const [isMobile, setIsMobile] = useState(false);
  const mobile = useMediaQuery({
    query: "(max-width:791px)",
  });

  useEffect(() => {
    if (mobile) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [mobile]);

  const serviceList = [
    {
      className: "listpage",
      title: "AI 쇼핑 검색",
      mainText: !isMobile ? (
        <>
          AI가 정리하고 분석하고
          <br />
          설명까지 척척해드릴게요
        </>
      ) : (
        <>
          AI가 정리하고 분석하고
          <br />
          설명까지 척척해드릴게요
        </>
      ),
      subText: !isMobile ? (
        <>
          검색 결과를 알기 쉽게 분석해서
          <br />더 빠르고 쉬운 쇼핑을 도와드려요.
        </>
      ) : (
        <>
          검색 결과를 알기 쉽게 분석해서
          <br />더 빠르고 쉬운 쇼핑을 도와드려요.
        </>
      ),
      webImage: [
        `https://postlab-public.s3.ap-northeast-2.amazonaws.com/screenshot/2_web.png`,
      ],
      mobileImage: [
        `https://postlab-public.s3.ap-northeast-2.amazonaws.com/screenshot/2_mobile.png`,
      ],
    },
    {
      className: "listpage",
      title: "가격에 대한 모든 것",
      mainText: !isMobile ? (
        <>
          얼마나 좋은 가격인지
          <br />
          사공사 쇼핑 AI가
          <br />다 분석해 드릴게요
        </>
      ) : (
        <>
          얼마나 좋은 가격인지 사공사
          <br />
          쇼핑 AI가 다 분석해 드릴게요
        </>
      ),
      subText: !isMobile ? (
        <>
          가격 분석 보고 무엇을 사던지
          <br />찐 최저가에 후회없이 구매하세요.
        </>
      ) : (
        <>
          가격 분석 보고 무엇을 사던지
          <br />찐 최저가에 후회없이 구매하세요.
        </>
      ),
      webImage: [
        `https://postlab-public.s3.ap-northeast-2.amazonaws.com/screenshot/3_web.png`,
      ],
      mobileImage: [
        `https://postlab-public.s3.ap-northeast-2.amazonaws.com/screenshot/3_mobile.png`,
      ],
    },

    {
      className: "listpage",
      title: "데일리 할인 리포트 & 가격 알림",
      mainText: !isMobile ? (
        <>
          필요한 정보만 정리해서
          <br />
          날마다 할인 소식 보내줄게요
        </>
      ) : (
        <>
          필요한 정보만 정리해서
          <br />
          날마다 할인 소식 보내줄게요
        </>
      ),
      subText: !isMobile ? (
        <>
          이쯤되면 사야지! 하는 가격이 있나요?
          <br />
          저희가 대신 지켜보고 알림 드릴게요.
        </>
      ) : (
        <>
          이쯤되면 사야지! 하는 가격이 있나요?
          <br />
          저희가 대신 지켜보고 알림 드릴게요.
        </>
      ),
      webImage: [
        `https://postlab-public.s3.ap-northeast-2.amazonaws.com/screenshot/4_web.png`,
      ],
      mobileImage: [
        `https://postlab-public.s3.ap-northeast-2.amazonaws.com/screenshot/4_mobile.png`,
      ],
    },

    {
      className: "listpage",
      title: "내 쇼핑 활동 꺼내보기",
      mainText: !isMobile ? (
        <>
          상품 검색하고 구경했던 내용
          <br />다 모아줄게요
        </>
      ) : (
        <>
          상품 검색하고 구경했던 내용
          <br />다 모아줄게요
        </>
      ),
      subText: !isMobile ? (
        <>
          내 활동 기록 활용해서
          <br />더 쉽고 빠르게 핫딜 쇼핑해요.
        </>
      ) : (
        <>
          내 활동 기록 활용해서
          <br />더 쉽고 빠르게 핫딜 쇼핑해요.
        </>
      ),
      webImage: [
        `https://postlab-public.s3.ap-northeast-2.amazonaws.com/screenshot/5_web.png`,
      ],
      mobileImage: [
        `https://postlab-public.s3.ap-northeast-2.amazonaws.com/screenshot/5_mobile.png`,
      ],
    },
    {
      className: "listpage",
      title: "사고 싶은 아이템 모두 모아",
      mainText: !isMobile ? (
        <>
          어느 쇼핑 앱 상품이던지
          <br />
          컬렉션에 담으면 계속 변하는
          <br />
          시세를 알려줄게요
        </>
      ) : (
        <>
          어느 쇼핑 앱 상품이던지
          <br />
          컬렉션에 담으면 계속 변하는
          <br />
          시세를 알려줄게요
        </>
      ),
      subText: !isMobile ? (
        <>
          사고 싶은 상품 모두 담고 매일 볼 필요 없이
          <br />
          할인 · 역대 최저가 알림 쏙쏙 받아봐요.
        </>
      ) : (
        <>
          사고 싶은 상품 모두 담고 매일 볼 필요 없이
          <br />
          할인 · 역대 최저가 알림 쏙쏙 받아봐요.
        </>
      ),
      webImage: [
        `https://postlab-public.s3.ap-northeast-2.amazonaws.com/screenshot/6_web.png`,
      ],
      mobileImage: [
        `https://postlab-public.s3.ap-northeast-2.amazonaws.com/screenshot/6_mobile.png`,
      ],
    },
  ];

  return (
    <StyledScreenShotScreen>
      <ScreenShotScreenWrap>
        <TopSection>
          <img
            alt=""
            className="mainPage"
            src={
              !isMobile
                ? `https://postlab-public.s3.ap-northeast-2.amazonaws.com/screenshot/1_web.png`
                : `https://postlab-public.s3.ap-northeast-2.amazonaws.com/screenshot/1_mobile.png`
            }
          />
          <OutLink404>
            <img
              alt=""
              className="outlink"
              src={`/assets/sagonsa_logo_outlink.png`}
            />
            <CustomHeader>
              AI와 함께
              <br />더 쉬운 쇼핑 검색
            </CustomHeader>

            <BlackButton
              onClick={() => {
                isMobile
                  ? redirectToAppStore()
                  : (window.location.href = "/qr");
              }}
              text="앱 다운로드"
              size="big"
              width={!isMobile ? "180px" : "calc(100% - 32px)"}
            />
          </OutLink404>
        </TopSection>

        {serviceList.map((service, index) => (
          <IntroService index={index} key={service.title}>
            <div className="imgWrap">
              {(!isMobile ? service.webImage : service.mobileImage).map(
                (image, index) => (
                  <MotionSection isImag={true} key={index}>
                    <img
                      alt=""
                      className={
                        service.className === "comments"
                          ? service.className + index
                          : service.className
                      }
                      src={image}
                    />
                  </MotionSection>
                )
              )}
            </div>
            <MotionSection isMobile={isMobile} isImage={false}>
              <div className="textWrap">
                <h1 className="title">{service.title}</h1>
                <h1 className="mainText">{service.mainText}</h1>
                <h2 className="subText">{service.subText}</h2>
              </div>
            </MotionSection>
          </IntroService>
        ))}
      </ScreenShotScreenWrap>
    </StyledScreenShotScreen>
  );
};

function MotionSection({ children, isImage, isMobile }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();
  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView, mainControls]);

  return (
    <section ref={ref}>
      <motion.div
        variants={{
          hidden: { opacity: 0, y: isImage ? 100 : 200 },
          visible: { opacity: 1, y: 0 },
        }}
        initial="hidden"
        animate={mainControls}
        transition={{
          ease: "easeInOut",
          duration: isImage ? 1 : 0.5,
          delay: isImage ? 0.25 : isMobile ? 0.3 : 0.4,
        }}
      >
        {children}
      </motion.div>
    </section>
  );
}

const StyledScreenShotScreen = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto 0 auto;

  @media (max-width: 791px) {
    margin: 0 auto 0 auto;
  }
`;

const TopSection = styled.div`
  display: flex;

  flex-direction: row;
  align-items: center;
  justify-content: center;
  ${BgGray050}
  /* margin-bottom: 60px; */
  padding: 200px 0;
  width: 100%;
  gap: 175px;
  @media (max-width: 791px) {
    ${BgMonoWhite}
    margin-bottom: 0px;
    padding: 60px 0 0 0;
    flex-direction: column;
    gap: 100px;
    width: 100%;
  }
`;

const OutLink404 = styled.div`
  display: flex;
  flex-direction: column;

  /* width: 100%; */

  /* justify-content: center; */
  /* gap: 30px; */

  @media (max-width: 791px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .outlink {
    width: 324px;
    height: 51px;
    margin-bottom: 30px;

    @media (max-width: 791px) {
      width: 223px;
      height: 35px;
      margin-bottom: 24px;
    }
  }
`;

const CustomHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  ${Header48}

  @media (max-width: 791px) {
    ${Header30}
  }
`;

const ScreenShotScreenWrap = styled.div`
  /* margin-top: 480px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  @media (max-width: 791px) {
    /* margin-top: 200px; */
  }

  .mainPage {
    /* margin-bottom: 200px; */

    width: 375px;
    height: 800px;
    border-radius: 24px;
    background: #fff;
    box-shadow: 0px 4px 14px 0px rgba(21, 31, 48, 0.04),
      0px 18px 30px 0px rgba(21, 31, 48, 0.05),
      0px 40px 60px 0px rgba(21, 31, 48, 0.07),
      0px 100px 100px 0px rgba(21, 31, 48, 0.08),
      0px 110px 120.07px 0px rgba(21, 31, 48, 0.08),
      0px 50px 250px 0px rgba(21, 31, 48, 0.08);

    @media (max-width: 791px) {
      width: 260px;
      height: 560px;

      box-shadow: 0px 8px 10px 0px rgba(21, 31, 48, 0.05),
        0px 20px 30px 0px rgba(21, 31, 48, 0.07),
        0px 50px 50px 0px rgba(21, 31, 48, 0.08),
        0px 50px 80.07px 0px rgba(21, 31, 48, 0.08),
        0px 30px 150px 0px rgba(21, 31, 48, 0.08);
    }
  }

  .imgWrap {
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 26px;

    @media (max-width: 791px) {
      gap: 12px;
    }
  }
`;

const IntroService = styled("div").withConfig({
  shouldForwardProp: (prop) => prop !== "index",
})`
  gap: 135px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;

  ${(props) => (props.index === 3 ? " height: 1280px" : " height: 1200px")};

  ${(props) =>
    props.index % 2 !== 0 && "background: var(--gray-010, #FAFAFA);"};
  flex-direction: ${(props) => (props.index % 2 === 0 ? "row-reverse" : "row")};

  @media (max-width: 791px) {
    flex-direction: column;
    height: 1002px;
    gap: 60px;

    ${(props) => (props.index === 3 ? " height: 992px" : " height: 1002px")};
  }

  .listpage {
    width: 375px;
    height: 800px;

    border-radius: 24px;
    box-shadow: 0px 4px 14px 0px rgba(21, 31, 48, 0.04),
      0px 18px 30px 0px rgba(21, 31, 48, 0.05),
      0px 40px 60px 0px rgba(21, 31, 48, 0.07),
      0px 100px 100px 0px rgba(21, 31, 48, 0.08),
      0px 110px 120.07px 0px rgba(21, 31, 48, 0.08),
      0px 50px 250px 0px rgba(21, 31, 48, 0.08);

    @media (max-width: 791px) {
      width: 260px;
      height: 540px;

      box-shadow: 0px 8px 10px 0px rgba(21, 31, 48, 0.05),
        0px 20px 30px 0px rgba(21, 31, 48, 0.07),
        0px 50px 50px 0px rgba(21, 31, 48, 0.08),
        0px 50px 80.07px 0px rgba(21, 31, 48, 0.08),
        0px 30px 150px 0px rgba(21, 31, 48, 0.08);
    }
  }

  .textWrap {
    width: 588px;
    @media (max-width: 791px) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100vw;
    }
  }

  .title {
    font-style: normal;

    font-size: 20px;
    line-height: 28px;
    color: #5a00ff;

    @media (max-width: 791px) {
      font-size: 15px;
      line-height: 21px;
      color: #5a00ff;
      padding: 0 20px;
    }
  }

  .mainText {
    margin-top: 18px;

    font-size: 48px;
    line-height: 60px;
    color: #000000;

    @media (max-width: 791px) {
      margin-top: 8px;

      font-size: 24px;
      line-height: 32px;
      padding: 0 20px;
    }
  }

  .subText {
    margin-top: 30px;

    font-size: 30px;
    line-height: 40px;
    color: #858585;

    @media (max-width: 791px) {
      margin-top: 10px;

      font-size: 16px;
      line-height: 24px;
      padding: 0 20px;
    }
  }
`;

export default ScreenShotPage;
